import { matchPath } from 'react-router';
import { GA4Pages, GA4Sections } from './interfaces';

export const getLocation = (): {
  locationPrimary: GA4Pages | 'Unknown';
  locationSecondary: GA4Sections | 'Unknown' | undefined;
} => {
  const match = matchPath('/:primary/:secondary', window.location.pathname);

  if (!match || !match.params) {
    return {
      locationPrimary: 'Unknown',
      locationSecondary: 'Unknown',
    };
  }

  const { primary, secondary } = match.params;

  let locationPrimary = primary as PortalRoutes.Primary;
  let locationSecondary = secondary as PortalRoutes.Secondary | undefined;

  if (locationPrimary === 'auction' || locationPrimary === 'profile') {
    return {
      locationPrimary: GA4Pages[locationPrimary],
      locationSecondary: undefined,
    };
  }

  if (locationPrimary === 'hampton' || locationPrimary === 'tenders') {
    return {
      locationPrimary: GA4Pages[locationPrimary],
      locationSecondary: GA4Sections[locationSecondary as PortalRoutes.Secondary],
    };
  }

  if (locationPrimary === 'auctions' && locationSecondary) {
    return {
      locationPrimary: GA4Pages.auctions,
      locationSecondary: GA4Sections[locationSecondary],
    };
  }

  return {
    locationPrimary: 'Unknown',
    locationSecondary: 'Unknown',
  };
};

export const ga4NullEventFields = {
  eventName: null,
  location1: null,
  location2: null,
  location3: null,
  location4: null,
  location5: null,
  action: null,
  actionedObject: null,
  actionedValue: null,
  actionedObject2: null,
  actionedValue2: null,
  actionedObject3: null,
  actionedValue3: null,
  actionedObject4: null,
  actionedValue4: null,
  actionedObject5: null,
  actionedValue5: null,
  fieldName: null,
  fieldValue: null,
  topic: null,
};
